@use "~@tietokilta/ilmomasiina-components/src/styles/definitions" as *;

.tab {
  border-left: 1px solid grey;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
}

.sortable-item {
  flex-direction: row !important;
  margin-bottom: 1rem;

  .quota-body, .question-body {
    margin: 0;
    padding: 1.25rem;
    flex-grow: 1;
    background: $secondary-background;

    .no-focus {
      user-select: none;
    }
  }

  .handler {
    position: relative;
    width: 40px;
    background: $body-bg;
    border-right: 1px solid $card-border-color;
    cursor: move;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 5px;
      right: 5px;
      top: 50%;
      height: 1px;
      background: $card-border-color;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 5px;
      right: 5px;
      top: calc(50% - 10px);
      height: 21px;
      border-top: 1px solid $card-border-color;
      border-bottom: 1px solid $card-border-color;
    }
  }
}

@include media-breakpoint-down(xs) {
  .event-editor--question-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    > *:not(:last-child) {
      margin-right: $spacer * 0.5;
    }
    > button:first-of-type {
      margin-left: auto;
    }
  }
}

.event-editor--buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex-wrap: wrap;
  row-gap: $spacer;
  margin-bottom: $spacer;

  .spinner-border {
    align-self: center;
  }

  .event-editor--public-status {
    padding: 0 20px;

    .event-editor--bubble {
      display: inline-block;
      vertical-align: -0.15em;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      margin-right: 10px;

      &.draft {
        background-color: orange;
      }

      &.public {
        background-color: green;
      }
    }
  }
}

.event-editor--animated {
  transition: all 0.3s ease;
}
