@use "~@tietokilta/ilmomasiina-components/src/styles/definitions" as *;

.audit-log--pagination {
  display: flex;
  align-items: baseline;

  .form-control {
    width: 10ch;
  }
}
.audit-log--table {
  td {
    font-size: 0.9em;
  }

  thead th {
    vertical-align: top;
  }
}
.audit-log--filter {
  display: flex;
  margin-top: $spacer * .5;

  > * {
    width: 0;
    flex: 1 1;
  }
}
