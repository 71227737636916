@use "~@tietokilta/ilmomasiina-components/src/styles/definitions" as *;

.navbar {
  background: $primary;
  margin-bottom: 1.5rem;
}

.navbar-brand {
  color: color-yiq($primary) !important;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.dg-logo {
  margin-right: 1rem;
}
