@use "~@tietokilta/ilmomasiina-components/src/styles/definitions" as *;

// Map react-widgets variables to Bootstrap theme
@use "react-widgets/scss/styles.scss" with (
  $widget-bg:                         $input-bg,
  $widget-disabled-bg:                $input-disabled-bg,
  $widget-border-color:               $input-border-color,
  $widget-focus-box-shadow:           $input-focus-box-shadow,

  $input-bg:                          $input-bg,
  $input-disabled-bg:                 $input-disabled-bg,
  $input-color:                       $input-color,
  $input-placeholder-color:           $input-placeholder-color,
  $input-disabled-placeholder-color:  $input-placeholder-color,
  $input-border-color:                $input-border-color,
  $input-focus-border-color:          $input-focus-border-color,

  $list-selected-bg:                  $dropdown-link-active-bg,
  $list-selected-color:               $dropdown-link-active-color,
  $list-focus-bg:                     $dropdown-link-hover-bg,
  $list-focus-color:                  $dropdown-link-hover-color,

  $components: ("Combobox"),
);

// Stop combobox input showing through datepicker
.rw-combobox-input {
  z-index: auto !important;
}
